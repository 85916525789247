import React from 'react';
import ImageCrossfade from './ImageCrossfade.jsx';


export default function App() {

  const images = [
    'images/ba.jpg',
    'images/dubai.jpg',
    'images/mumbai.jpg',
    'images/shanghai.jpg',
    'images/warsaw.jpg',
  ];

  return (
    <main id="home" className="flex flex-col min-h-screen">

      <div className='relative bg-white z-20'>
        <div className="fixed top-0 left-0 right-0 z-10 flex flex-row bg-white text-black font-bold py-8">
          <div className='flex-1 text-sm md:text-2xl font-extrabold ml-8'><a href="#home">Eternal Capital Group</a></div>
          <div className='flex-1 text-sm md:text-base text-right mr-8'><a href='#about'>About</a></div>
          <div className='flex-none text-sm md:text-base text-right mr-8'><a href='#contact'>Contact</a></div>
        </div>
      </div>

      <section>
        <ImageCrossfade images={images} duration={6000} />

        <div className='z-10 text-white text-2xl md:text-4xl mt-auto mb-auto mx-2 text-center font-bold'>
          <p className='text-2xl md:text-4xl'>Over 20 years of Emerging Market Equity investing</p>
          <p className='text-xl md:text-4xl pt-3'>We are locals investing in our regions</p>
        </div>
      </section>


      <section id="about" className='z-10'>
        <div className='flex flex-col flex-grow justify-center mx-auto max-w-prose'>

          <div className='text-2xl mt-4 ml-3 mr-3 text-center font-bold text-black'>
            We are an experienced team of local investment professionals with international experience navigating inefficient Global Emerging Market Equities
          </div>

          <div className="mt-4 p-2 rounded font-bold tracking-wider">
            <p className='text-justify mt-3 ml-3 mr-3'>Each team member has on average 20 years of investment experience Investment professionals were born in the regions in which they invest.</p>
            <p className="text-justify mt-2 ml-3 mr-3">Emerging markets are heterogenous culturally, structurally and politically, so local people with international experience are the best positioned to understand all the nuances of so many different and complicated markets.</p>  
            <p className="text-justify mt-2 mb-4 ml-3 mr-3">Our team members are all owners of the firm. Investment professionals are motivated to solely focus on returns. The firm&apos;s style is team-oriented. We do not depend on any one individual for our success.</p>
          </div>
        </div>
      </section>

      <section id="contact" className='bg-slate-50 z-10'>
        <div className="flex flex-col justify-center min-h-screen ml-4">
            <div className='text-2xl font-bold mb-4'>
              Connect With Us
            </div>

            <div>
              <div><b>Trevor Angus</b></div>
              <div>email: <a className="text-blue-600 underline" href="mailto:ta@eternalcapitalgroup.com">ta@eternalcapitalgroup.com</a></div>

              <div className='my-4'>
                <div><b>Patricio Danziger</b></div>
                <div>email: <a className="text-blue-600 underline" href="mailto:pd@eternalcapitalgroup.com">pd@eternalcapitalgroup.com</a></div>
              </div>
            </div>

        </div>
      </section>

    </main>
  )
}

