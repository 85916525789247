import { useState, useEffect } from 'react';

import "./ImageCrossfade.css";

const ImageCrossfade = ({ images, duration }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, duration);

    return () => clearInterval(interval);
  }, [currentImageIndex, duration, images.length]);

  return (
    <div className="crossfade-container">
      {images.map((imageUrl, index) => (
        <img
          key={index}
          src={imageUrl}
          alt={`Image ${index}`}
          className={`object-cover crossfade-image ${
            index === currentImageIndex ? 'visible' : ''
          }`}
        />
      ))}
    </div>
  );
};

export default ImageCrossfade;

